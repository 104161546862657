import React from "react";
import { Link } from "@reach/router";

import "../utils/css/portfolio.css";
import Carousel from "./Carousel";
function Portfolio() {
  return (
    <div id="portfolio-wrapper">
      <h2 className="portfolio-title">Portfolio</h2>
      <Carousel
        getChildren={(images, onClickRightBtn, onClickLeftBtn) => {
          return (
            <div id="portfolio_items-wrapper">
              <div className="right-arrow" onClick={() => ""}></div>
              <div className="left-arrow"></div>
              {images.map((image) => {
                return (
                  <Link
                    className="portfolio-item"
                    to={`/portfolio/view/${"id"}`}
                  >
                    <div
                      className="item"
                      style={{
                        backgroundImage: `url(${image})`,
                      }}
                    ></div>
                    <div className="portfolio-item__details">Aubred Glass</div>
                  </Link>
                );
              })}
            </div>
          );
        }}
        totalImages={[
          "https://images.unsplash.com/photo-1560393464-5c69a73c5770?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=701&q=80",
          "https://images.unsplash.com/photo-1526198330131-9b0bc79625e4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
          "https://cgaxisimg.ams3.cdn.digitaloceanspaces.com/2018/03/cgaxis_models_97_11a.jpg",
          "https://cgaxisimg.ams3.cdn.digitaloceanspaces.com/2018/03/cgaxis_models_97_11a.jpg",
          "https://images.unsplash.com/photo-1526198330131-9b0bc79625e4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
          "https://images.unsplash.com/photo-1560393464-5c69a73c5770?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=701&q=80",
        ]}
        imgsPerSlide={6}
      />

      {/* <div id="portfolio_items-wrapper">
        <div className="right-arrow" onClick={() => ""}></div>
        <div className="left-arrow"></div>
        <div className="portfolio-item">
          <div
            className="item"
            style={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1560393464-5c69a73c5770?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=701&q=80)",
            }}
          ></div>
        </div>
        <div className="portfolio-item">
          <div
            className="item"
            style={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1526198330131-9b0bc79625e4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)",
            }}
          ></div>
        </div>
        <div className="portfolio-item">
          <div
            className="item"
            style={{
              backgroundImage:
                "url(https://cgaxisimg.ams3.cdn.digitaloceanspaces.com/2018/03/cgaxis_models_97_11a.jpg)",
            }}
          ></div>
        </div>

        <div className="portfolio-item">
          <div
            className="item"
            style={{
              backgroundImage:
                "url(https://cgaxisimg.ams3.cdn.digitaloceanspaces.com/2018/03/cgaxis_models_97_11a.jpg)",
            }}
          ></div>
        </div>
      </div> */}
    </div>
  );
}

export default Portfolio;
