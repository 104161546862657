import React from "react";
import { Link } from "@reach/router";

import "../utils/css/nav.css";
function Nav() {
  return (
    <div className="nav-wrapper">
      <Link to="/" style={{ textDecoration: "none", color: "initial" }}>
        <h2 className="nav-logo">
          <span
            style={{
              background: "-webkit-linear-gradient(#557cc5, #041577)",
              webkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            RPC
          </span>{" "}
          Factory
        </h2>
      </Link>
      <div className="nav-links">
        <Link className="nav-link" to="/portfolio">
          Portfolio
        </Link>
        <Link className="nav-link" to="/contact-us">
          Contact
        </Link>
      </div>
    </div>
  );
}

export default Nav;
