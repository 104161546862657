const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const getFileType = file => {
  if (
    typeof file.type === "string" &&
    file.type !== "" &&
    file.type.length > 0
  ) {
    return file.type.split("/")[1].toLowerCase()
  } else if (
    typeof file.name === "string" &&
    file.name !== "" &&
    file.name.length > 0
  ) {
    let fileNameSplit = file.name.split(".")
    return fileNameSplit[fileNameSplit.length - 1].toLowerCase()
  }
}

export default async function handleDrop({ data, typeRequired }) {
  // console.log(data, typeRequired);
  const filesData = Object.values(data).map(async file => {
    const fileType = getFileType(file)
    // console.log(fileType, file, data, typeRequired);

    if (typeRequired.includes(fileType)) {
      return {
        name: file.name,
        type: fileType,
        file: file,
      }
    } else {
      // console.log('WHAT!>?');
    }
  })
  return await Promise.all(filesData)
}
