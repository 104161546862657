import React, { useState } from "react";
import Landing from "./components/Landing";
import Portfolio from "./components/Portfolio";
import ProductView from "./components/ProductView";
import Nav from "./components/Nav";
import Contact from "./components/Contact";
import { Router, Link } from "@reach/router";

import "./App.css";

function App() {
  const [showForm, changeFormShowStatus] = useState(false);
  return (
    <div className="app">
      <Nav />
      <Router>
        <Landing path="/" />
        <Portfolio path="/portfolio" />
        <ProductView path="/portfolio/view/:id" />
        <Contact path="/contact-us" />
      </Router>
    </div>
  );
}

export default App;
