import React from "react";

export default class Carousel extends React.Component {
  constructor() {
    super();

    this.state = {
      images: [],
    };
  }

  componentDidMount() {
    this.setState({
      images: this.props.totalImages,
    });
  }

  pullContentOnRight() {}

  pullContentOnLeft() {}

  render() {
    return this.props.getChildren(
      this.state.images,
      this.pullContentOnLeft,
      this.pullContentOnRight
    );
  }
}

// this.props.totalImages
// this.props.getChildren
// this.props.imgsPerSlide
