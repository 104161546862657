import React, { useState } from "react";
import ProjectForm from "./projectForm";
import "../utils/css/form.css";
import archvisionLogo from "../images/archvision-logo.png";

function Landing() {
  const [showForm, changeFormShowStatus] = useState(false);
  return (
    <div className="upload-form_large">
      <div className="main-content">
        <div className="main_text-wrapper">
          <p className="main_text">
            <span>We Create</span>
            <br />
            High-Quality{" "}
            <span
              style={{
                background: "-webkit-linear-gradient(#557cc5, #041577)",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
                fontWeight: "800",
              }}
            >
              RPC
            </span>
            <br /> <span>for your Projects!</span>
          </p>
        </div>

        <div className="main_form-wrapper">
          <ProjectForm showForm={showForm} />
          <button
            className={`main_request-btn ${showForm && "hide-it"}`}
            onClick={() => changeFormShowStatus(true)}
          >
            Request RPC
          </button>
        </div>
      </div>
      <p className="footer_text">
        In Partnership with
        <img
          src={archvisionLogo}
          height="50px"
          style={{ marginLeft: "10px" }}
        />
      </p>
    </div>
  );
}

export default Landing;
