import React from "react";
import "../utils/css/contact.css";

function Contact() {
  return (
    <div id="contact_wrapper">
      <form className="contact_form">
        <h2 className="contact_title">Contact Us</h2>
        <input
          type="email"
          className="contact_input"
          placeholder="Your E-Mail Address"
        />
        <textarea
          type="text"
          className="contact_textarea"
          placeholder="Write something to us"
        />
        <button className="contact_btn">Send</button>
      </form>
    </div>
  );
}

export default Contact;
