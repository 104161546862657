import React from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader2 } from "three/examples/jsm/loaders/OBJLoader2";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { MtlObjBridge } from "three/examples/jsm/loaders/obj2/bridge/MtlObjBridge";
import obj from "../utils/house/test_obj.obj";
import mtlFile from "../utils/house/test_obj.mtl";
import textureFile from "../utils/house/testobj_tex.jpg";
import * as THREE from "three";
import "../utils/css/productView.css";

export default class ProductView extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    let container;
    let camera;
    let renderer;
    let scene;
    let house;
    (function init() {
      container = document.querySelector(".scene");

      container.addEventListener("mousedown", (e) => {
        console.log(e);
      });

      //Create scene
      scene = new THREE.Scene();

      const fov = 25;
      const aspect = container.clientWidth / container.clientHeight;
      const near = 0.1;
      const far = 1000;

      var textureLoader = new THREE.TextureLoader();
      var texture = textureLoader.load(textureFile);
      texture.flipY = false;

      //Camera setup
      camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, 5, 150);

      const ambient = new THREE.HemisphereLight(0xb1e1ff, 0xb97a20, 1);
      scene.add(ambient);

      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.set(5, 10, 2);
      scene.add(light);
      scene.add(light.target);

      //Renderer
      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(container.clientWidth, container.clientHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      let controls = new OrbitControls(camera, renderer.domElement);

      container.appendChild(renderer.domElement);

      //Load Model
      let loader = new OBJLoader2();
      let mtlLoader = new MTLLoader();

      mtlLoader.load(mtlFile, function (mtlParseResult) {
        const materials = MtlObjBridge.addMaterialsFromMtlLoader(
          mtlParseResult
        );

        loader.addMaterials(materials);
        loader.load(
          obj,
          function (gltf) {
            console.log(gltf, "vendum");
            scene.add(gltf);
            animate();
          },
          function (xhr) {
            console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
          },
          function (error) {
            console.log("An error happened");
          }
        );
      });
    })();

    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }
  }

  render() {
    return (
      <div id="product-view">
        <div
          className="product-view__obj-view"
          style={{
            flex: 0.5,
            height: "400px",
            width: "400px",
            marginRight: "40px",
          }}
        >
          <div
            className="scene"
            style={{
              height: "100%",
              width: "100%",
              outline: "none",
              // border: "1px solid #000",
            }}
          ></div>
        </div>
        <div className="product-view__content-view">
          <h2 className="product-view__content-view__title">HomeCare Robot</h2>
          <hr
            style={{
              borderTop: "1px solid #CCC",
              backgroundColor: "transparent",
              display: "block",
              height: "1px",
              borderWidth: "0",
              lineHeight: "19px",
              marginTop: "0",
              marginBottom: "14px",
            }}
          />
          <div className="product-view__content-view__description">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur
            distinctio eligendi deserunt. Tempore quos doloremque provident
            deleniti quam quis et quae fuga natus nihil, quibusdam nisi numquam
            nobis eius illum? Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Dolor, adipisci. Dolorem ab et ut tenetur, facere velit
            similique sint molestiae officia fugit, aperiam excepturi quibusdam,
            quos deserunt eius iste! Itaque. Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Et rerum ipsum veritatis in, odio
            porro provident eum molestias nam saepe? Reprehenderit accusantium
            minus magnam ratione rem nostrum vel voluptatibus id!
          </div>
        </div>
      </div>
    );
  }
}
