import React, { Component } from "react"
class DragAndDrop extends Component {
  state = {
    drag: false,
    dropped: false,
    images: [],
  }
  dropRef = React.createRef()

  handleDrag = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  handleDragIn = e => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (
      this.props.handleDragIn &&
      e.dataTransfer.items &&
      e.dataTransfer.items.length > 0
    ) {
      this.props.handleDragIn(e)
      this.setState({ drag: true })
    }
  }
  handleDragOut = e => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.props.handleDragOut && this.dragCounter === 0) {
      this.props.handleDragOut(e)
      this.setState({ drag: false })
    }
  }
  handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false, dropped: true })
    if (
      (this.props.multiple === true &&
        Object.keys(e.dataTransfer.files) &&
        Object.keys(e.dataTransfer.files).length > 0) ||
      (this.props.multiple === false &&
        Object.keys(e.dataTransfer.files) &&
        Object.keys(e.dataTransfer.files).length === 1)
    ) {
      this.props.handleDrop(e.dataTransfer.files)
      this.setState({
        images: e.dataTransfer.files,
      })
      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }

  // reupload() {
  // 	this.setState({
  // 		images: [],
  // 		drag: false,
  // 		dropped: false
  // 	});
  // }

  componentDidMount() {
    let div = this.dropRef.current
    this.dragCounter = 0
    this.props.handleDragIn &&
      div.addEventListener("dragenter", this.handleDragIn)
    this.props.handleDragOut &&
      div.addEventListener("dragleave", this.handleDragOut)
    div.addEventListener("dragover", this.handleDrag)
    div.addEventListener("drop", this.handleDrop)
  }
  componentWillUnmount() {
    let div = this.dropRef.current
    this.props.handleDragIn &&
      div.removeEventListener("dragenter", this.handleDragIn)
    this.props.handleDragOut &&
      div.removeEventListener("dragleave", this.handleDragOut)
    div.removeEventListener("dragover", this.handleDrag)
    div.removeEventListener("drop", this.handleDrop)
  }
  render() {
    return (
      <div style={this.props.style} ref={this.dropRef}>
        {/* {this.state.dropped && this.state.images.length > 0 ? (
					<div>
						<span>Got the images!</span>
						<button className="head_form_reupload_btn" onClick={this.reupload.bind(this)}>
							Re Upload
						</button>
					</div>
				) : ( */}
        {this.props.children}
        {/* )} */}
      </div>
    )
  }
}
export default DragAndDrop
