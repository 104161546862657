import React, { Component } from "react";
import DragAndDrop from "../utils/DragAndDrop";
import extractFromDrop from "../utils/extractFromDrop";
import upload_pic from "../images/upload.svg";
import { v1 as uuid } from "uuid";

const randomText = uuid();

export default class FormPage extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      projectName: "",
      images: [],
      description: "",
      isAgreementsAgreed: false,
      formSubmitStatus: "NONE",
      // createAccountChecked: false,
      authStatus: false,
      appLoadStatus: true,
      landingHeadSectionHeight: undefined,
      showDownloadModal: false,
    };

    this.inputOpenFileRef = React.createRef();
    // this.awsHandlers = this.props.awsHandlers;
    this.addImages = this.addImages.bind(this);
    this.toggleFormSubmitStatus = this.toggleFormSubmitStatus.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.imageDropThroughBrowse = this.imageDropThroughBrowse.bind(this);
    this.reupload = this.reupload.bind(this);
    // this.logout = this.logout.bind(this);
    this.showOpenFileDlg = this.showOpenFileDlg.bind(this);
    this.changeFormValue = this.changeFormValue.bind(this);
  }
  addImages(images) {
    this.setState({ images: images });
  }

  toggleFormSubmitStatus(status) {
    this.setState({ formSubmitStatus: status });
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const {
      email,
      projectName,
      isAgreementsAgreed,
      images,
      description,
    } = this.state;

    // function formStatusChanger(status) {
    // 	toggleFormSubmitStatus(status);
    // }

    // console.log('TELL ME THE PROPS', formData, images, sendFormData, uploadImages);

    if (email && projectName && isAgreementsAgreed) {
      this.toggleFormSubmitStatus("PENDING");

      const randomProjectName = `${projectName} ||| ${randomText}`;

      if (images.length > 0) {
        const imageKeys = await this.props.awsHandlers.uploadImages(
          images,
          randomProjectName
        );
        this.props.awsHandlers.sendFormData(
          {
            email: email,
            projectName: projectName,
            description: description,
            // createAccountChecked: createAccountChecked,
            imageKeys,
            randomProjectName,
          },
          this.toggleFormSubmitStatus
        );
      } else {
        this.props.awsHandlers.sendFormData(
          {
            email: email,
            projectName: projectName,
            description: description,
            // createAccountChecked: createAccountChecked,
            randomProjectName,
          },
          this.toggleFormSubmitStatus
        );
      }
    }
  }

  async handleDrop(data) {
    const inputImages = await extractFromDrop(data);
    this.addImages(inputImages);

    return function getImageData(inputImages = inputImages) {
      return inputImages;
    };
  }

  imageDropThroughBrowse(e) {
    this.handleDrop(e.target.files);
  }

  reupload() {
    this.addImages([]);
  }

  showOpenFileDlg() {
    this.inputOpenFileRef.current.click();
  }

  changeFormValue(e) {
    // console.log(e, e.target, e.target.value, e.target.name);

    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    return this.state.formSubmitStatus !== "SUCCESS" ? (
      <div
        className={`head_form_wrapper ${this.props.showForm && "bounce-in"}`}
      >
        <h1 className="head_right_title">Request RPC Today!!</h1>
        <form
          className="head_form"
          onSubmit={this.onFormSubmit}
          // style={{ width: "400px" }}
        >
          <input
            type="email"
            name="email"
            // id="email"
            placeholder="Email *"
            className="head_form_input"
            value={this.state.email}
            onChange={this.changeFormValue}
            required
            label="email"
          />
          <input
            type="text"
            placeholder="Project Name *"
            className="head_form_input"
            name="projectName"
            value={this.state.projectName}
            onChange={this.changeFormValue}
            required
            label="project name"
          />
          <textarea
            type="text"
            placeholder="Description (optional)"
            name="description"
            className="head_form_textarea"
            value={this.state.description}
            onChange={this.changeFormValue}
          />
          {this.state.images && this.state.images.length > 0 ? (
            <div className="head_form_file_input input_form_uploaded">
              {this.state.images.map((image) => {
                return <p className="head_form_file_name">{image.imageName}</p>;
              })}
              <button
                className="head_form_reupload_btn"
                onClick={this.reupload}
              >
                Re Upload
              </button>
            </div>
          ) : (
            <div className="head_form_file_input">
              <DragAndDrop
                handleDrop={this.handleDrop}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={upload_pic}
                  height="20px"
                  width="20px"
                  className="head_form_file_input_img"
                  alt="upload_pic_icon"
                />
                <div className="head_form_file_input_text">
                  Drop files to upload or
                </div>
                <input
                  ref={this.inputOpenFileRef}
                  onChange={this.imageDropThroughBrowse}
                  type="file"
                  style={{ display: "none" }}
                  multiple
                  label="drop files"
                />

                <button
                  className="head_form_file_input_btn"
                  type="button"
                  onClick={this.showOpenFileDlg}
                >
                  Browse
                </button>
                {/* <input type="file" id="head_form_browse_file" /> */}
              </DragAndDrop>
            </div>
          )}
          <div className="head_form_terms_conditions">
            <input
              type="checkbox"
              name="isAgreementsAgreed"
              id=""
              checked={this.state.isAgreementsAgreed}
              onChange={(e) =>
                this.changeFormValue({
                  target: { name: e.target.name, value: e.target.checked },
                })
              }
              required
              label="agreements checkbox"
            />
            <span className="head_form_terms_conditions_text">
              I agree to the{" "}
              <a
                href="/terms-and-conditions"
                target="_ blank"
                className="head_form_terms_conditions_text_link"
                title="Terms & Conditions"
              >
                Terms and Conditions
              </a>
            </span>
            <br />
            {/* <input
											type="checkbox"
											name="createAccountChecked"
											id=""
											checked={this.state.createAccountChecked}
											onChange={(e) =>
												this.changeFormValue({
													target: { name: e.target.name, value: e.target.checked }
												})}
											required
											label="create account checkbox"
										/>
										<span className="head_form_terms_conditions_text">Create free account</span> */}
          </div>
          {/* <p className="head_form_consent">
										By using revitfam.com, you are agreeing to our Terms of Service.
									</p> */}
          {this.state.formSubmitStatus === "PENDING" ? (
            <button
              className="head_form_submit_btn_pending"
              type="submit"
              disabled
            >
              Submitting...
            </button>
          ) : this.state.formSubmitStatus === "NONE" ? (
            <button className="head_form_submit_btn" type="submit">
              Submit
            </button>
          ) : (
            ""
          )}
        </form>
      </div>
    ) : (
      <div className="form_success">
        <span className="form_success_text">
          <p className="form_success_thank">
            Thank you for submitting your request!
          </p>{" "}
          One of our customer service representatives will contact you shortly.
          <br />
          <br />
          You can also contact us at:{" "}
          <a
            href="mailto:support@revitfam.com?Subject=Requesting%20a%20new%20project"
            target="_top"
            className="form_success_email"
            title="Mail Us"
          >
            support@revitfam.com
          </a>
          <br />
          <br />
          Meanwhile, please sign in to manage your project.{" "}
        </span>
      </div>
    );
  }
}
